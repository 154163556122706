/*!

=========================================================
* Material Dashboard PRO React - v1.8.0 Based on Material Dashboard PRO v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// variables and mixins
@import "gefis/variables";
@import "gefis/mixins";
@import "gefis/shadows";

// plugin css
@import "gefis/plugins/plugin-nouislider";
@import "gefis/plugins/plugin-perfect-scrollbar";
@import "gefis/plugins/plugin-react-datetime";
@import "gefis/plugins/plugin-react-bootstrap-sweetalert";
@import "gefis/plugins/plugin-react-chartist";
@import "gefis/plugins/plugin-react-big-calendar";
@import "gefis/plugins/plugin-react-jvectormap";
@import "gefis/plugins/plugin-react-table";
@import "gefis/plugins/plugin-react-tagsinput";

// Core CSS
@import "gefis/misc";
@import "gefis/fileupload";
@import "gefis/fixed-plugin";

/*.css-f91fgu {
  background: none !important;
}*/

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}

.swal2-container.swal2-center {
  z-index: 1300;
}

.texto-normal {
  white-space: pre-wrap;
  text-align: left !important;
}
